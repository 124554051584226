import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { marketCategoryModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

const MarketCategoryModal = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useRecoilState(marketCategoryModalState);

  const { data } = useSWR(modal && `/market/category/main`);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 300);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupHeader>
          카테고리 선택
          <Close onClick={handleClose} />
        </PopupHeader>
        <SecondCategory>
          <CategoryBox
            onClick={() => {
              handleClose();
              navigate("/market/list/all", { replace: true });
              window.location.reload();
            }}
          >
            <CategoryImage
              src={require("../../assets/images/all-category.png")}
              alt=""
            />
            전체 선택
          </CategoryBox>
{/*          <CategoryBox
            onClick={() => {
              handleClose();
              navigate("/market/list/subscribe", { replace: true });
              window.location.reload();
            }}
          >
            <CategoryImage
              src={require("../../assets/icons/market-pin-icon.svg").default}
              alt=""
            />
            구독 상품
          </CategoryBox>*/}
          <CategoryBox
            onClick={() => {
              handleClose();
              navigate("/market/list/set", { replace: true });
              window.location.reload();
            }}
          >
            <CategoryImage
              src={require("../../assets/icons/market-set-icon.svg").default}
              alt=""
            />
            세트 상품
          </CategoryBox>
          {data &&
            data?.categories?.map(
              (
                item: {
                  category_image: string;
                  category_name: string;
                  id: number;
                },
                i: number
              ) => (
                <CategoryBox
                  key={"market_category_" + i}
                  onClick={() => {
                    handleClose();
                    navigate(`/market/list/${item?.id}`, { replace: true });
                    window.location.reload();
                  }}
                >
                  <CategoryImage src={item.category_image} alt="" />
                  {item.category_name}
                </CategoryBox>
              )
            )}
        </SecondCategory>
      </Wrapper>
    </ModalWrapper>
  );
};

export default MarketCategoryModal;

const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 11px;
  line-height: 1.4;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
  object-fit: cover;
`;

const CategoryBox = styled.div`
  width: calc(25% - 18px);
  margin-right: 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: keep-all;

  &.active {
    font-weight: 700;
    color: ${(props) => props.theme.color.gray1};
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: 22px;
  }
`;

const SecondCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: calc(100% - 120px);
  overflow: auto;
  padding: 10px 24px;

  &.detail {
    height: calc(100% - 120px);
  }
`;

const FirstCategory = styled.div`
  padding: 14px 20px;

  & > button {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 6px;
    background: ${(props) => props.theme.color.gray7};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.2px;

    & > svg {
      width: 5px;
      height: 9px;
      margin-right: 8px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
