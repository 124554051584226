import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import {mapModalState} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { ReactComponent as NaverMap } from "../../assets/icons/navermap.svg";
import { ReactComponent as KakaoMap } from "../../assets/icons/kakaomap.svg";

const varUA = navigator.userAgent;

const MapModal = () => {
  const [modal, setModal] = useRecoilState(mapModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleConfirm = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };
  if (modal === null) return null;

  const handleNaverMap = (address: string | undefined, address_detail: string | undefined) => {
    try {
      if (varUA.match("coffik/Android") != null || varUA.match("coffik15/Android") != null) {
        window.open(
            `nmap://search?query=${encodeURIComponent(
                address + " " + address_detail
            )}`,
            "_self"
        );
      } else if (varUA.match("coffik/iOS") != null) {
        window.open(
            `nmap://search?query=${encodeURIComponent(
                address + " " + address_detail
            )}`
        );
      } else {
        window.open(
            `https://map.naver.com/v5/search/${encodeURIComponent(
                address + " " + address_detail
            )}`
        );
      }
    } catch (error) {}
  };

  const handleKakaoMap = (address: string | undefined, address_detail: string | undefined) => {
    try {
      if (varUA.match("coffik/Android") != null || varUA.match("coffik15/Android") != null) {
        window.open(
            `kakaomap://search?q=${encodeURIComponent(
                address + " " + address_detail
            )}`,
            "_self"
        );
      } else if (varUA.match("coffik/iOS") != null) {
        window.open(
            `kakaomap://search?q=${encodeURIComponent(
                address + " " + address_detail
            )}`
        );
      } else {
        window.open(
            `https://map.kakao.com/link/search/${encodeURIComponent(
                address + " " + address_detail
            )}`
        );
      }
    } catch (error) {}
  };

  return (
    <ModalWrapper position="bottom" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          <List onClick={() => { handleNaverMap(modal?.address, modal?.address_detail) }}><NaverMap /> 네이버 지도</List>
          <List onClick={() => { handleKakaoMap(modal?.address, modal?.address_detail) }}><KakaoMap /> 카카오맵</List>
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default MapModal;

const List = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: ${(props) => props.theme.color.gray1};
  letter-spacing: -0.028px;

  &.select {
    font-weight: 700;
  }

  & > svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding: 20px 20px 20px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
