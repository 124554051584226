import React from "react";
import Preview from "../../pages/Preview";
import { RouteType } from "../../types/types";

import TwoButtonModal from "../modal/TwoButtonModal";
import TermModal from "../modal/TermModal";
import AlertModal from "../modal/AlertModal ";
import SelectModal from "../modal/SelectModal";
import ToastModal from "../modal/ToastModal";
import CategoryModal from "../modal/CategoryModal";
import MainCategoryModal from "../modal/MainCategoryModal";
import CafeInfoModal from "../modal/CafeInfoModal";
import CafeListModal from "../modal/CafeListModal";
import MapModal from "../modal/MapModal";
import WithdrawalModal from "../modal/WithdrawalModal";
import MarketCategoryModal from "../modal/MarketCategoryModal";
import SubscribeModal from "../modal/SubscribeModal";
import FeedMenuModal from "../modal/FeedMenuModal";
import MarketBuyModal from "../modal/MarketBuyModal";
import SetBuyModal from "../modal/SetBuyModal";
import PostModal from "../modal/PostModal";
import PictureDetailModal from "../modal/PictureDetailModal";
import BusinessInfoModal from "../modal/BusinessInfoModal";
import ReviewMenuModal from "../modal/ReviewMenuModal";
import ReportModal from "../modal/ReportModal";
import Loading from "../elements/LoadingComponent";
import MapMarketCategoryModal from "../modal/MapMarketCategoryModal";

const WebRoute = ({ element, ...props }: RouteType) => {
  return (
    <Preview>
      {element}

      <MarketCategoryModal />
      <CategoryModal />
      <MapMarketCategoryModal />
      <MainCategoryModal />
      <CafeInfoModal />
      <CafeListModal />
      <MapModal />
      <SubscribeModal />
      <MarketBuyModal />
      <SetBuyModal />
      <FeedMenuModal />
      <ReviewMenuModal />
      <ReportModal />

      <AlertModal />
      <TwoButtonModal />
      <TermModal />
      <SelectModal />
      <ToastModal />
      <WithdrawalModal />
      <PostModal />
      <PictureDetailModal />
      <BusinessInfoModal />

      <Loading />
    </Preview>
  );
};

export default WebRoute;
