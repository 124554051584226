import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { toastModalState } from "../../utils/atom";

import { ReactComponent as Check } from "../../assets/icons/toast-modal-icon.svg";
import { useLocation } from "react-router-dom";

const ToastModal = () => {
  const [modal, setModal] = useRecoilState(toastModalState);
  const { pathname } = useLocation();

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null && modal) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.top = "10px";
      });

      setTimeout(() => {
        try {
          //@ts-ignore
          wrapperRef.current.style.top = "-100px";

          setTimeout(() => {
            setModal(null);
          }, 200);
        } catch (error) {}
      }, 3000);
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      setTimeout(() => {
        if(pathname.indexOf("/cafe/order/") !== -1){
          //@ts-ignore
          wrapperRef.current.style.top = "-100px";
          setModal(null);
        }
      }, 10);

      if (
        modal?.text !== "리뷰를 삭제했습니다." &&
        modal?.text !== "게시글을 삭제했습니다." &&
        modal?.text !== "차단되었습니다." &&
        modal?.text !== "작성한 피드가 등록되었어요!" &&
        modal?.text !== "톡 게시글이 수정되었습니다." &&
        modal?.text !== "톡 게시글이 등록되었습니다." &&
        modal?.text !== "장바구니에 메뉴를 추가했어요!"
      ) {
        //@ts-ignore
        wrapperRef.current.style.top = "-100px";
        setModal(null);
      }
    } catch (error) {}
  }, [window.location.href]);

  return (
    <Modal ref={wrapperRef}>
      {modal?.check && <Check />}
      <p>{modal?.text}</p>
    </Modal>
  );
};

export default ToastModal;

const Modal = styled.div`
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  max-width: calc(100% - 40px);
  width: fit-content;
  padding: 9px 30px;

  display: flex;
  align-items: center;

  transition: all 0.3s;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);

  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    color: ${(props) => props.theme.color.white};
    white-space: nowrap;
  }

  & > svg {
    margin-right: 7px;
    width: 10px;
    height: 7px;
  }
`;
