import React from "react";
import { AmountType } from "../../types/types";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { toastModalState } from "../../utils/atom";

import { ReactComponent as Plus } from "../../assets/icons/amount-plus-icon.svg";
import { ReactComponent as PlusD } from "../../assets/icons/amount-plus-disabled-icon.svg";
import { ReactComponent as Minus } from "../../assets/icons/amount-minus-icon.svg";
import { ReactComponent as MinusD } from "../../assets/icons/amount-minus-disabled-icon.svg";

const Amount = ({ amount, setAmount, max, handleAmountClick }: AmountType) => {
  const setToastModal = useSetRecoilState(toastModalState);

  return (
    <Box>
      <AmountButton
        type="button"
        onClick={() => {
          if (amount > 1) setAmount(amount - 1);
          handleAmountClick && handleAmountClick(amount - 1);
        }}
      >
        {amount <= 1 ? <MinusD /> : <Minus />}
      </AmountButton>
      <p>{amount}</p>
      <AmountButton
        type="button"
        onClick={() => {
          if (max) {
            if (amount < max) {
              setAmount(amount + 1);
              handleAmountClick && handleAmountClick(amount + 1);
            } else {
              setToastModal({
                check: false,
                text: "최대 선택 가능한 수량을 초과했어요.",
              });
            }
          } else {
            if (amount < 999) {
              setAmount(amount + 1);
              handleAmountClick && handleAmountClick(amount + 1);
            }
          }
        }}
      >
        {amount >= 999 ? <PlusD /> : <Plus />}
      </AmountButton>
    </Box>
  );
};

export default Amount;

const AmountButton = styled.button`
  width: 26px;
  min-width: 26px;
  height: 26px;

  & > svg {
    width: 26px;
    height: 26px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: 0 8px;
    width: 30px;
    min-width: 30px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;
    text-align: center;
  }
`;
