import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { mainCategoryModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/category-arrow-icon.svg";

const MainCategoryModal = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useRecoilState(mainCategoryModalState);

  const { data } = useSWR(
    modal && modal?.categoryId
      ? modal?.type === "매장"
        ? `/cafe/category/sub?main_category_id=${modal?.categoryId}`
        : `/market/category/sub?main_category_id=${modal?.categoryId}`
      : ""
  );

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 300);
  };

  const handleClick = (id: number, name: string) => {
    window.localStorage.setItem("mainCT", String(modal?.categoryId));
    window.localStorage.setItem("subCT", String(id));
    window.localStorage.setItem("CtStr", `${modal?.category} > ${name}`);

    if (modal?.type === "매장") navigate("/order");
    else navigate(`/market/list/${modal?.categoryId}`);

    handleClose();
  };

  const handleAll = () => {
    window.localStorage.setItem("mainCT", String(modal?.categoryId));
    window.localStorage.setItem("subCT", "all");
    window.localStorage.setItem("CtStr", `${modal?.category} > 전체`);

    if (modal?.type === "매장") navigate("/order");
    else navigate(`/market/list/${modal?.categoryId}`);

    handleClose();
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupHeader>
          카테고리 선택
          <Close onClick={handleClose} />
        </PopupHeader>
        <FirstCategory>
          <button type="button" onClick={handleClose}>
            <Arrow />
            {modal?.category}
          </button>
        </FirstCategory>
        <SecondCategory>
          <CategoryBox onClick={handleAll}>
            <CategoryImage
              src={require("../../assets/images/all-category.png")}
              alt=""
            />
            <b>전체 선택</b>
          </CategoryBox>
          {data &&
            data?.categories?.map(
              (
                item: {
                  category_image: string;
                  category_name: string;
                  id: number;
                },
                i: number
              ) => (
                <CategoryBox
                  key={"second_" + modal?.categoryId + "_category_" + i}
                  onClick={() => handleClick(item.id, item.category_name)}
                >
                  <CategoryImage src={item.category_image} alt="" />
                  {item.category_name}
                </CategoryBox>
              )
            )}
        </SecondCategory>
      </Wrapper>
    </ModalWrapper>
  );
};

export default MainCategoryModal;

const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 11px;
  line-height: 1.4;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
  object-fit: cover;
`;

const CategoryBox = styled.div`
  width: calc(25% - 18px);
  margin-right: 24px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: keep-all;

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: 22px;
  }
`;

const SecondCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: calc(100% - 120px);
  overflow: auto;
  padding: 10px 24px;
`;

const FirstCategory = styled.div`
  padding: 14px 20px;

  & > button {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 6px;
    background: ${(props) => props.theme.color.gray7};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.2px;

    & > svg {
      width: 5px;
      height: 9px;
      margin-right: 8px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
