import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  alertModalState,
  feedReplyMenuState,
  reportModalState,
  toastModalState,
  twoButtonModalState,
} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { api } from "../../utils/api";

const FeedReplyMenuModal = () => {
  const [modal, setModal] = useRecoilState(feedReplyMenuState);

  const setToastModal = useSetRecoilState(toastModalState);
  const setReportModal = useSetRecoilState(reportModalState);
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);
  const setAlertModal = useSetRecoilState(alertModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleDelete = async () => {
    const {
      data: { success, alert },
    } = await api.delete(
      "/community/comment/delete?comment_id=" + modal?.replyId
    );

    if (success) {
      setToastModal({ text: "댓글이 삭제되었습니다.", check: true });
      modal?.mutate && modal?.mutate();
      handleClose();
    } else {
      window.alert(alert);
    }
  };

  const handleReport = async (value: string[]) => {
    const formData = new FormData();
    formData.append(
      "target_type",
      modal?.type ? modal?.type : "coffeed_comment"
    );
    //@ts-ignore
    formData.append("target_id", modal?.replyId);
    formData.append("reason", value.join(","));

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/report", formData);

    if (success) {
      setAlertModal({ title: "신고 완료", text: "신고가 완료되었습니다." });
      handleClose();
    } else {
      window.alert(alert);
    }
  };

  const handleBlock = async () => {
    const formData = new FormData();
    //@ts-ignore
    formData.append("target_user_id", modal?.userId);

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/user/block", formData);

    if (success) {
      if (data2?.type === 1)
        setToastModal({ text: "차단되었습니다.", check: true });
      modal?.mutate();
      handleClose();
    } else {
      window.alert(alert);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>댓글 더보기</PopupTitle>
        <PopupText>
          {modal?.myReply ? (
            <>
              <List
                onClick={() => {
                  modal?.onEdit && modal?.onEdit();
                  handleClose();
                }}
              >
                수정
              </List>
              <List
                onClick={() =>
                  setTwoButtonModal({
                    title: "댓글 삭제",
                    text: "댓글을 삭제하시겠습니까?",
                    confirmText: "삭제",
                    onConfirm: handleDelete,
                  })
                }
              >
                삭제
              </List>
            </>
          ) : (
            <>
              <List
                onClick={() =>
                  setReportModal({ onClick: (value) => handleReport(value) })
                }
              >
                작성자 신고
              </List>
              <List
                onClick={() =>
                  setTwoButtonModal({
                    title: "작성자 차단",
                    text: "선택 회원을 차단하시겠습니까?",
                    confirmText: "차단",
                    onConfirm: handleBlock,
                  })
                }
              >
                작성자 차단
              </List>
            </>
          )}
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default FeedReplyMenuModal;

const List = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray1};
  letter-spacing: -0.028px;

  &.select {
    font-weight: 700;
  }

  & > svg {
    width: 14px;
    height: 10px;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding: 3px 20px 20px;
`;

const PopupTitle = styled.div`
  padding: 26px 20px 16px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
