import { atom } from "recoil";
import {
  CafeType,
  CouponType,
  MapCafeType,
  NowCafeOrderType,
  ProductOptionType,
} from "../types/api";
import { OptionType } from "../types/types";

type TwoButtonModalType = {
  title: string;
  text: string;
  confirmText?: string;
  cancleText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  onCancelConfirm?: () => void;
} | null;

export const twoButtonModalState = atom<TwoButtonModalType>({
  key: `twoButtonModalState`,
  default: null,
});

type alertModalType = {
  title: string;
  text: string;
  bottom?: React.ReactNode | React.ReactNode[];
  findType?: string;
  confirmText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
} | null;

export const alertModalState = atom<alertModalType>({
  key: `alertModalState`,
  default: null,
});

type loadingType = {
  visible: boolean;
} | null;

export const loadingState = atom<loadingType>({
  key: `loadingState`,
  default: null,
});

type termModalType = {
  type: string;
  onClick?: () => void;
} | null;

export const termModalState = atom<termModalType>({
  key: `termModalState`,
  default: null,
});

type businessInfoModalType = {
  visabled: boolean;
} | null;

export const businessInfoModalState = atom<businessInfoModalType>({
  key: `businessInfoModalState`,
  default: null,
});

type mapModalType = { address?: string; address_detail?: string; } | null;

type selectModalType = {
  title?: string;
  options: { value: string; text?: string }[];
  value?: string;
  onSelect?: (value: { value: string; text?: string }) => void;
} | null;

export const mapModalState = atom<mapModalType>({
  key: `mapModalState`,
  default: null,
});

export const selectModalState = atom<selectModalType>({
  key: `selectModalState`,
  default: null,
});

type toastModalType = {
  text: string;
  check: boolean;
} | null;

export const toastModalState = atom<toastModalType>({
  key: `toastModalState`,
  default: null,
});

type PostModalType = {
  visible: boolean;
  onClick?: (postcode: string, address: string) => void;
} | null;

export const postModalState = atom<PostModalType>({
  key: `postModalState`,
  default: null,
});

type joinModalType = {
  visable: boolean;
  kakao?: boolean;
  apple?: boolean;
} | null;

export const joinModalState = atom<joinModalType>({
  key: `joinModalState`,
  default: null,
});

type findEmailModalType = {
  email: string;
  provider: string | null;
} | null;

export const findEmailModalState = atom<findEmailModalType>({
  key: `findEmailModalState`,
  default: null,
});

type mainOrdeStatusStateType = {
  visiable: boolean;
} | null;

export const mainOrdeStatusState = atom<mainOrdeStatusStateType>({
  key: `mainOrdeStatusState`,
  default: null,
});

type mainCategoryModalType = {
  category: string;
  categoryId: number;
  type: "매장" | "마켓";
} | null;

export const mainCategoryModalState = atom<mainCategoryModalType>({
  key: `mainCategoryModalState`,
  default: null,
});

type categoryModalType = {
  main: number;
  sub: number;
  category: string;
  type: "cafe" | "market";
  onSelect: (main: number, sub: number, text: string) => void;
} | null;

export const categoryModalState = atom<categoryModalType>({
  key: `categoryModalState`,
  default: null,
});

type subCategoryModalType = {
  data: any;
  selData: any;
  onSelect: (sub: any, subText: any) => void;
} | null;

export const subCategoryModalState = atom<subCategoryModalType>({
  key: `subCategoryModalState`,
  default: null,
});

type marketCategoryModalType = {
  visible: boolean;
} | null;

export const marketCategoryModalState = atom<marketCategoryModalType>({
  key: `marketCategoryModalState`,
  default: null,
});

type reportModalType = {
  onClick: (value: string[]) => void;
} | null;

export const reportModalState = atom<reportModalType>({
  key: `reportModalState`,
  default: null,
});

type feedMenuType = {
  userId?: number;
  feedId?: number;
  myFeed?: boolean;
  mySubscribe?: boolean;
  type: "coffeed" | "coffee_talk" | "cafe" | "market" | "subscribe";
} | null;

export const feedMenuState = atom<feedMenuType>({
  key: `feedMenuState`,
  default: null,
});

type feedReplyMenuType = {
  type: "coffeed_comment" | "coffee_talk_comment";
  userId: number;
  replyId: number;
  replyValue: string;
  myReply: boolean;
  mutate: () => void;
  onEdit: () => void;
} | null;

export const feedReplyMenuState = atom<feedReplyMenuType>({
  key: `feedReplyMenuState`,
  default: null,
});

type cafeModalType = {
  onClick: (
    value: CafeType | string | { address_name: string; place_name: string }
  ) => void;
} | null;

export const cafeModalState = atom<cafeModalType>({
  key: `cafeModalState`,
  default: null,
});

type cafeInfoModalType = {
  item: MapCafeType;
} | null;

export const cafeInfoModalState = atom<cafeInfoModalType>({
  key: `cafeInfoModalState`,
  default: null,
});

type cafeListModalType = {
  item: MapCafeType[];
  order: OptionType;
  setOrder: (value: OptionType) => void;
} | null;

export const cafeListModalState = atom<cafeListModalType>({
  key: `cafeListModalState`,
  default: null,
});

type ReviewMenuType = {
  type: "cafe" | "market";
  userId: number;
  order_id: number;
  reviewId: number;
  myReview: boolean;
  mutate: () => void;
} | null;

export const ReviewMenuState = atom<ReviewMenuType>({
  key: `ReviewMenuState`,
  default: null,
});

type WithdrawalModalType = {
  title: string;
  onClick: () => void;
} | null;

export const withdrawalModalState = atom<WithdrawalModalType>({
  key: `withdrawalModalState`,
  default: null,
});

type couponModalType = {
  price: number;
  cafe: number;
  onClick: (item: CouponType) => void;
} | null;

export const couponModalState = atom<couponModalType>({
  key: `couponModalState`,
  default: null,
});

type marketCouponModalType = {
  use: number[];
  price: number;
  onClick: (item: CouponType, price: number) => void;
  delivery_price: number;
} | null;

export const marketCouponModalState = atom<marketCouponModalType>({
  key: `marketCouponModalState`,
  default: null,
});

type subscribeModalType = {
  product: {
    id: number;
    images: { id: number; image_url: string }[];
    is_option: 0 | 1;
    is_set: 0 | 1;
    is_subscribe: 0 | 1;
    market_id: number;
    max_option: number;
    options: ProductOptionType[];
    price: number;
    product_detail: string;
    product_name: string;
    product_tag: string;
    qty: number;
    review_count: number;
    review_score: string;
    subscribe_price: number;
    subscribe_sale_percent: number;
  };
  set_delivery_price: number;
  set_free_delivery_minimum: number;
} | null;

export const subscribeModalState = atom<subscribeModalType>({
  key: `subscribeModalState`,
  default: null,
});

export const marketBuyModalState = atom<subscribeModalType>({
  key: `marketBuyModalState`,
  default: null,
});

export const setBuyModalState = atom<subscribeModalType>({
  key: `setBuyModalState`,
  default: null,
});

type subscribeDetailModalType = {
  id: number;
  order: string;
  date: string;
} | null;

export const subscribeDetailModalState = atom<subscribeDetailModalType>({
  key: `subscribeDetailModalState`,
  default: null,
});

type PictureDetailModalType = {
  start: number;
  picture: { image_url: string; id: number }[];
  onClick: (index: number) => void;
} | null;

export const pictureDetailModalState = atom<PictureDetailModalType>({
  key: `pictureDetailModalState`,
  default: null,
});

type MobileCarrierType = {
  onClick: (type: string) => void;
} | null;

export const MobileCarrierState = atom<MobileCarrierType>({
  key: `MobileCarrierState`,
  default: null,
});

type profileMenuType = {
  onChange: () => void;
  onRemove: () => void;
} | null;

export const ProfileMenuState = atom<profileMenuType>({
  key: `ProfileMenuState`,
  default: null,
});
