import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { couponModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";
import useSWR from "swr";
import { Dday, Dot } from "../../utils/CommonFunction";
import moment from "moment";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

const CouponModal = () => {
  const [modal, setModal] = useRecoilState(couponModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const { data } = useSWR(modal && `/cafe/coupon?cafe_id=${modal?.cafe}`);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 300);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupHeader>
          쿠폰 선택
          <Close onClick={handleClose} />
        </PopupHeader>
        <PopupText>
          {data?.coupons?.map((item: any, i: number) => {
            if (item?.is_issued_coupon === "Y")
              return (
                <CouponInfo
                  className={
                    modal?.price < item?.minimum_order_price ? "receive" : ""
                  }
                  onClick={() => {
                    if (modal?.price < item?.minimum_order_price) {
                    } else {
                      modal?.onClick && modal?.onClick(item);
                      handleClose();
                    }
                  }}
                >
                  <h1>
                    {item?.cafe_id === modal?.cafe && (
                      <CafeCoupon>전용쿠폰</CafeCoupon>
                    )}
                    {Dot(item?.price)}
                    {item?.discount_type === "price" ? "원" : "%"}
                  </h1>
                  <p>{item?.name}</p>
                  <h3>
                    {Dot(item?.minimum_order_price)}원 이상 주문 시 사용 가능
                  </h3>
                  <Date>
                    <p>{Dday(item?.end_date)}</p>
                    <span />
                    {moment(item?.end_date).format("YYYY.MM.DD")} 까지
                  </Date>
                </CouponInfo>
              );
          })}
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CouponModal;

const CafeCoupon = styled.div`
  padding: 4px;
  border-radius: 3px;
  background: ${(props) => props.theme.color.red};
  font-size: 9px;
  font-weight: 600;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.white};
  margin-right: 8px;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray3};

  & > p {
    color: ${(props) => props.theme.color.red};
    font-size: 12px;
    font-weight: 700;
  }

  & > span {
    display: block;
    width: 1px;
    height: 8px;
    background: ${(props) => props.theme.color.line};
    margin: 0 6px;
  }

  .receive & {
    color: ${(props) => props.theme.color.gray4};

    & > p {
      color: ${(props) => props.theme.color.redSub};
    }
  }
`;

const CouponInfo = styled.div`
  width: 100%;
  padding: 20px 26px;
  position: relative;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.color.gray5};
  cursor: pointer;

  & > h1 {
    font-size: 17px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray1};
    display: flex;
    align-items: center;
  }

  & > p {
    margin: 8px 0 13px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: ${(props) => props.theme.color.gray1};
  }

  & > h3 {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray3};
  }

  &::after {
    position: absolute;
    content: "";
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 30px 30px 0;
    width: 15px;
    height: 30px;
    border: 1px solid ${(props) => props.theme.color.gray5};
    border-left: 0;
    background: ${(props) => props.theme.color.white};
  }

  &:nth-of-type(n + 2) {
    margin-top: 10px;
  }

  &.receive {
    cursor: default;
    border-color: ${(props) => props.theme.color.gray5};

    &::after {
      border-color: ${(props) => props.theme.color.gray5};
    }

    & > h1 {
      color: ${(props) => props.theme.color.gray4};
    }

    & > p {
      color: ${(props) => props.theme.color.gray4};
    }

    & > h3 {
      color: ${(props) => props.theme.color.gray4};
    }
  }
`;

const PopupText = styled.div`
  padding: 6px 20px;
  width: 100%;
  height: calc(100% - 58px);
  overflow: auto;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
