import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import {Dot, handleHoliday, mapOrderOptions} from "../../utils/CommonFunction";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  cafeInfoModalState,
  cafeListModalState,
  mapModalState,
  toastModalState,
} from "../../utils/atom";

import Map from "../../assets/icons/map.jpg";
import { ReactComponent as Like } from "../../assets/icons/like-gray-icon.svg";
import { ReactComponent as LikeAct } from "../../assets/icons/like-act-icon.svg";
import { ReactComponent as Distance } from "../../assets/icons/distance-icon.svg";
import { ReactComponent as Star } from "../../assets/icons/list-star-icon.svg";

const CafeList = ({
  distance,
  end_time,
  id,
  is_my_like,
  is_close = "N",
  image_url,
  name,
  review_count,
  review_score,
  start_time,
  tag,
  type,
  handleLikeClick,
  holidays,
  order_type,
  is_open,
  handleListClick,
  address,
  address_detail
}: {
  distance: number;
  end_time: string;
  id: number;
  is_my_like: "Y" | "N";
  is_close?: "Y" | "N";
  image_url: string;
  name: string;
  review_count: number;
  review_score: string;
  start_time: string;
  tag: string;
  type?: string;
  handleLikeClick?: any;
  holidays?: string;
  order_type?: number;
  address?: string;
  address_detail?: string;
  is_open?: 0 | 1;
  handleListClick?: () => void;
}) => {
  const navigate = useNavigate();
  const now = moment();

  const setCafeModal = useSetRecoilState(cafeListModalState);
  const setCafeInfoModal = useSetRecoilState(cafeInfoModalState);
  const setToastModal = useSetRecoilState(toastModalState);
  const setMapModal = useSetRecoilState(mapModalState);

  const [like, setLike] = useState<boolean>(false);

  useEffect(() => {
    setLike(is_my_like === "Y");
  }, [is_my_like]);

  const handleLike = async () => {
    const formData = new FormData();
    formData.append("cafe_id", String(id));

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/cafe/like", formData);

    if (success) {
      if (
        data2?.like === 0 &&
        (window.location.href.includes("/order") ||
          window.location.href.includes("/coffik/result"))
      )
        setToastModal({
          text: "찜한 카페에 추가되었어요!",
          check: true,
        });
      setLike(data2?.like === 0);
      handleLikeClick && handleLikeClick();
    } else {
      window.alert(alert);
    }
  };

  const handleClick = () => {
    handleListClick && handleListClick();
    navigate(`/cafe/${id}`);
    setCafeModal(null);
    setCafeInfoModal(null);
  };

  return (
    <Wrapper className={type}>
      <div>
        {image_url && (
          <ImageBox onClick={handleClick}>
            <Image src={encodeURI(image_url)} alt="" />
            {order_type === 0 ? (!start_time && !end_time) ? '' : (holidays && handleHoliday(holidays) ? (
                    <>
                      <Open />
                      <OpenTime>
                        오늘은
                        <br />
                        쉬는 날이에요
                      </OpenTime>
                    </>
                ) : (now.isBefore(moment(`${now.format("YYYY.MM.DD")} ${start_time}`, "YYYY.MM.DD HH:mm")) || now.isAfter(moment(`${now.format("YYYY.MM.DD")} ${end_time}`, "YYYY.MM.DD HH:mm"))) ?
                  (
                    <>
                      <Open />
                      <OpenTime>
                        오픈 예정시간
                        <br />
                        {start_time}
                      </OpenTime>
                    </>
                  ) : ('')
            ) : ((!start_time && !end_time) ? '' : (holidays && handleHoliday(holidays) ? (
                    <>
                      <Open />
                      <OpenTime>
                        오늘은
                        <br />
                        쉬는 날이에요
                      </OpenTime>
                    </>
                ) : (now.isBefore(moment(`${now.format("YYYY.MM.DD")} ${start_time}`, "YYYY.MM.DD HH:mm")) || now.isAfter(moment(`${now.format("YYYY.MM.DD")} ${end_time}`, "YYYY.MM.DD HH:mm"))) ? (
                    (<>
                      <Open />
                      <OpenTime>
                        오픈 예정시간
                        <br />
                        {start_time}
                      </OpenTime>
                    </>)
                ) : ((is_open === 1 ? (<Order>주문가능</Order>) : ('')))
            ))}
          </ImageBox>
        )}
        <InfoArea>
          <Name>
            <p onClick={handleClick}>{name}</p>
            <MapImage src={Map} onClick={() => {
              setMapModal({ address: address, address_detail: address_detail})
            }}/>
            {like ? (
              <LikeAct className="act" onClick={handleLike} />
            ) : (
              <Like onClick={handleLike} />
            )}
          </Name>
          <Score onClick={handleClick}>
            <DistanceIcon />
            <b>{distance ? distance + "km" : "-"}</b>
            <span />
            <StarIcon />
            {Number(review_score).toFixed(1)}({Dot(review_count)})
          </Score>
          <Time onClick={handleClick}>
            <span>영업시간</span>
            {start_time} - {end_time}
          </Time>
          <TagBox onClick={handleClick}>
            {tag?.split(",").map((item: string, i: number) => (
              <div key={id + "_tag_" + i}>{item}</div>
            ))}
          </TagBox>
        </InfoArea>
      </div>
    </Wrapper>
  );
};

export default CafeList;

const MapImage = styled.img`
  margin-right: 10px;
  cursor: pointer;
`;

const Order = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  padding: 4px;
  border-radius: 3px;
  background: ${(props) => props.theme.color.gray1};
  font-size: 9px;
  font-weight: 600;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.white};
`;

const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 7px;

  & > div {
    margin-top: 4px;
    margin-right: 4px;
    padding: 2px 6px;
    border-radius: 3px;
    background: ${(props) => props.theme.color.gray7};
    font-size: 11px;
    font-weight: 500;
    line-height: 1.6;
    color: ${(props) => props.theme.color.gray2};
  }
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray2};

  & > span {
    color: ${(props) => props.theme.color.gray3};
    margin-right: 4px;
  }
`;

const StarIcon = styled(Star)`
  margin-right: 4px;
  width: 10px;
  height: 10px;
`;

const DistanceIcon = styled(Distance)`
  margin-right: 4px;
  width: 9px;
  height: 11px;
`;

const Score = styled.div`
  margin: 10px 0 8px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray3};

  & > b {
    font-weight: 600;
    color: ${(props) => props.theme.color.gray2};
  }

  & > span {
    display: block;
    margin: 0 5px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: ${(props) => props.theme.color.gray5};
  }
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > p {
    width: calc(100% - 27px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};
  }
`;

const InfoArea = styled.div`
  width: 100%;
`;

const OpenTime = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.white};
  z-index: 2;
  white-space: nowrap;
  text-align: center;
`;

const Open = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageBox = styled.div`
  position: relative;
  width: 94px;
  min-width: 94px;
  height: 94px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-right: 14px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  padding-left: 20px;
  cursor: pointer;

  &.map > div {
    padding: 6px 20px 24px 0;
  }

  & > div {
    padding: 16px 20px 16px 0;
    display: flex;
    align-items: center;
  }

  &:nth-of-type(n + 2) > div {
    border-top: 1px solid ${(props) => props.theme.color.line};
  }
`;
