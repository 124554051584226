import React from "react";
import styled from "styled-components";

import { ReactComponent as EmptyIcon } from "../../assets/icons/list-empty-icon.svg";

const Empty = ({
  text,
  type = "md",
}: {
  text: string;
  type?: "huge" | "large" | "sm" | "md";
}) => {
  return (
    <Wrapper className={type}>
      <EmptyIcon />
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </Wrapper>
  );
};

export default Empty;

const Wrapper = styled.div`
  width: 100%;
  padding: 94px 0;
  text-align: center;

  & > svg {
    width: 34px;
    height: 36px;
    display: block;
    margin: 0 auto 12px;
  }

  & > p {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    color: ${(props) => props.theme.color.gray4};
  }

  &.huge {
    padding: 152px 0;
  }

  &.large {
    padding: 122px 0;
  }
`;
