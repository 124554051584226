import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { businessInfoModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

import { ReactComponent as Close } from "../../assets/icons/test-close-icon.svg";

const BusinessInfoModal = () => {
  const [modal, setModal] = useRecoilState(businessInfoModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    try {
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef} className="ql-snow">
        <Header>
          <p>(주)피커스페이스</p>
          <Close onClick={handleClose} />
        </Header>
        <Content>
          <div>
            <p>대표이사</p>전유빈
          </div>
          <div>
            <p>사업자 등록번호</p>575-86-03145
          </div>
          <div>
            <p>주소</p>경기도 화성시 동탄중심상가2길 8,
            <br />
            604-에이137호
          </div>
          <div>
            <p>통신판매업 신고 번호</p>제 2023-화성동탄-3444호
          </div>
          <div>
            <p>이메일</p>help@coffik.com
          </div>
          <div>
            <p>고객센터</p>
            <u
              style={{ cursor: "pointer" }}
              onClick={() => window.open("tel:010-7592-1221")}
            >
              010-7592-1221
            </u>
          </div>
        </Content>
      </Wrapper>
    </ModalWrapper>
  );
};

export default BusinessInfoModal;

const Content = styled.div`
  padding: 14px 19px;

  & > div {
    display: flex;
    align-items: flex-start;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    color: ${(props) => props.theme.color.gray1};

    & > p {
      width: 110px;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: -0.026px;
      color: ${(props) => props.theme.color.gray4};
      margin-right: 10px;
    }

    &:nth-of-type(n + 2) {
      margin-top: 15px;
    }
  }
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  & > p {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    color: ${(props) => props.theme.color.gray1};
    text-align: center;
  }

  & > svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
`;
