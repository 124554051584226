import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Preview from "../../pages/Preview";
import { RouteType } from "../../types/types";
import auth from "../../utils/auth";
import { useSetRecoilState } from "recoil";
import { twoButtonModalState } from "../../utils/atom";
import useSWR from "swr";

import TwoButtonModal from "../modal/TwoButtonModal";
import TermModal from "../modal/TermModal";
import AlertModal from "../modal/AlertModal ";
import SelectModal from "../modal/SelectModal";
import FeedMenuModal from "../modal/FeedMenuModal";
import ToastModal from "../modal/ToastModal";
import ReportModal from "../modal/ReportModal";
import FeedReplyMenuModal from "../modal/FeedReplyMenuModal";
import CafeModal from "../modal/CafeModal";
import WithdrawalModal from "../modal/WithdrawalModal";
import CouponModal from "../modal/CouponModal";
import CategoryModal from "../modal/CategoryModal";
import SubscribeDetailModal from "../modal/SubscribeDetailModal";
import PostModal from "../modal/PostModal";
import PictureDetailModal from "../modal/PictureDetailModal";
import MobileCarrierModal from "../modal/MobileCarrierModal";
import BusinessInfoModal from "../modal/BusinessInfoModal";
import ProfileMenuModal from "../modal/ProfileMenuModal";
import MarketCouponModal from "../modal/MarketCouponModal";
import ReviewMenuModal from "../modal/ReviewMenuModal";
import MainCategoryModal from "../modal/MainCategoryModal";
import MapModal from "../modal/MapModal";
import MapMarketCategoryModal from "../modal/MapMarketCategoryModal";

const PrivateRoute = ({ element, ...props }: RouteType) => {
  const navigate = useNavigate();
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

  const { data, isLoading } = useSWR("/mypage");

  useEffect(() => {
    if (isLoading) return;
    if (!auth.getToken() || !data) {
      setTwoButtonModal({
        title: "로그인 필요",
        text: "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
        onConfirm: () => navigate("/"),
        onClose: () => navigate(-1),
      });
    } else {
      setTwoButtonModal(null);
    }
  }, [isLoading]);

  return (
    <Preview>
      {element}
      <CategoryModal />
      <MapMarketCategoryModal />
      <MainCategoryModal />
      <CafeModal />
      <FeedReplyMenuModal />
      <FeedMenuModal />
      <ReviewMenuModal />
      <ReportModal />
      <CouponModal />
      <MarketCouponModal />

      <AlertModal />
      <TwoButtonModal />
      <TermModal />
      <SelectModal />
      <MapModal />
      <ToastModal />
      <WithdrawalModal />
      <SubscribeDetailModal />
      <PostModal />
      <PictureDetailModal />
      <MobileCarrierModal />
      <BusinessInfoModal />
      <ProfileMenuModal />
    </Preview>
  );
};

export default PrivateRoute;
