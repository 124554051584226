import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { postModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";
import DaumPostcode from "react-daum-postcode";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

const PostModal = () => {
  const [modal, setModal] = useRecoilState(postModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 300);
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }
    const postcode = data?.zonecode; //우편번소
    const address = fullAddr; //주소

    modal?.onClick && modal.onClick(postcode, address);
    handleClose();
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupHeader>
          주소 검색
          <Close onClick={handleClose} />
        </PopupHeader>
        <PopupText>
          <DaumPostcode
            style={{
              width: "100%",
              height: "100%",
            }}
            onComplete={onCompletePost}
          />
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default PostModal;

const PopupText = styled.div`
  width: 100%;
  height: calc(100% - 58px);
  padding: 20px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
