import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import ModalWrapper from "./ModalWrapper";
import CheckBox from "../elements/CheckBox";
import { PopupTitle } from "../elements/Typography";
import { withdrawalModalState } from "../../utils/atom";

const WithdrawalModal = () => {
  const [modal, setModal] = useRecoilState(withdrawalModalState);

  const [check, setCheck] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClick = () => {
    modal?.onClick && modal?.onClick();
    handleConfirm();
  };

  const handleConfirm = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };
  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>{modal?.title}</PopupTitle>
        <Content>
          회원 탈퇴 시 24시간 이내 재가입이 불가능하며,
          <br />
          아래 데이터가 삭제되어 복구가 불가합니다.
        </Content>
        <NoticeBox>
          <p>쿠폰, 포인트, 구독 중인 상품, 커피 취향 테스트 데이터</p>
        </NoticeBox>
        <NoticeText className="notice">
          구독 중인 상품의 경우 구독 취소 처리됩니다.
        </NoticeText>
        <CheckContent>
          <CheckBox
            type="withdrawal"
            checked={check}
            onChange={() => {
              if (!check) setCheck(true);
              else setCheck(false);
            }}
          >
            <p className="checked">
              안내 사항을 모두 확인하였으며, 이에 동의합니다.
            </p>
          </CheckBox>
        </CheckContent>
        <ButtonContent>
          <button className="line" disabled={!check} onClick={handleClick}>
            회원 탈퇴
          </button>
          <button className="color" onClick={handleConfirm}>
            취소
          </button>
        </ButtonContent>
      </Wrapper>
    </ModalWrapper>
  );
};

export default WithdrawalModal;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
  padding: 26px 20px;
`;
const Content = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3c3b4b;
`;
const NoticeBox = styled.div`
  width: 100%;
  margin: 30px 0 12px;
  background: #f7f7fa;
  border-radius: 8px;
  padding: 12px 0;
  & > p {
    font-weight: 500;
    font-size: 13px;
    line-height: 19.5px;
    text-align: center;
    color: #151420;
  }
`;
const NoticeText = styled.div`
  color: #5d5d6d;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;
const CheckContent = styled.div`
  margin: 32px 0 30px;
  display: flex;
  align-items: center;
  & p.checked {
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    color: #151420;
  }
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > button {
    width: calc(50% - 5px);
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
    border-radius: 8px;

    &.line {
      color: #151420;
      border: 1px solid #151420;
      &:disabled {
        border: 1px solid #cdcdd6;
        color: #adadbc;
      }
    }
    &.color {
      color: #ffffff;
      background-color: #151420;
    }
  }
`;
