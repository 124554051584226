import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { subCategoryModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

const MapMarketCategoryModal = () => {
  const [modal, setModal] = useRecoilState(subCategoryModalState);

  const [data,setData] = useState<any>([]);
 
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (modal !== null) {
      setData(modal?.data);
    }else{
      setData([]);
    }
  }, [modal]);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      // if (modal !== null) {
      //   setData(modal?.data);
      //   setSeldata(modal?.selData);
      // }

      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
      setData([]);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
      setData([]);
    }, 300);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupHeader>
          카테고리 선택
          <Close onClick={handleClose} />
        </PopupHeader>

        <SecondCategory>
          <CategoryBox
            className={modal?.selData && modal?.selData?.filter((el:any)=>el.id === data?.id)[0] && !modal?.selData?.filter((el:any)=>el.id === data?.id)[0]?.subCateId ? "active" : ""}
            onClick={() => {
              modal?.onSelect && modal?.onSelect(null,null);
              handleClose();
            }}
          >
            <CategoryImage
              src={require("../../assets/images/all-category.png")}
              alt=""
            />
            전체 선택
          </CategoryBox>
          {data && data?.map(
            (
              item: {
                category_image: string;
                category_name: string;
                id: number;
              },
              i: number
            ) => (
              <CategoryBox
                key={"category_" + i}
                onClick={() => {
                  modal?.onSelect && modal?.onSelect(item.id,item.category_name);
                  handleClose();
                }}
              >
                <CategoryImage src={item.category_image} alt="" />
                {item.category_name}
              </CategoryBox>
            )
          )}
        </SecondCategory>
      </Wrapper>
    </ModalWrapper>
  );
};

export default MapMarketCategoryModal;

const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 11px;
  line-height: 1.4;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
  object-fit: cover;
`;

const CategoryBox = styled.div`
  width: calc(25% - 18px);
  margin-right: 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.active {
    font-weight: 700;
    color: ${(props) => props.theme.color.gray1};
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: 22px;
  }
`;

const SecondCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: calc(100% - 120px);
  overflow: auto;
  padding: 10px 24px;

  &.detail {
    height: calc(100% - 120px);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
