import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  alertModalState,
  feedMenuState,
  reportModalState,
  toastModalState,
  twoButtonModalState,
} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/icons/show-arrow-icon.svg";
import useSWR from "swr";

const FeedMenuModal = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useRecoilState(feedMenuState);
  const setToastModal = useSetRecoilState(toastModalState);
  const setReportModal = useSetRecoilState(reportModalState);
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);
  const setAlertModal = useSetRecoilState(alertModalState);

  const wrapperRef = useRef(null);

  const { data } = useSWR(modal?.mySubscribe ? "/mypage" : "");
  const card = data?.card_name;

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleReviewDelete = async () => {
    const {
      data: { success, alert },
    } = await api.delete(
      `/review/${modal?.type}/delete?${
        modal?.type === "market"
          ? "market_product_review_id=" + modal?.feedId
          : "cafe_menu_review_id=" + modal?.feedId
      }`
    );

    if (success) {
      setToastModal({ text: "리뷰를 삭제했습니다.", check: true });
      handleClose();
      navigate(-1);
    } else {
      window.alert(alert);
    }
  };
  const handleDelete = async () => {
    const {
      data: { success, alert },
    } = await api.delete(
      modal?.type === "coffee_talk"
        ? "/coffee/talk/delete?coffee_talk_id=" + modal?.feedId
        : "/coffeed/delete?coffeed_id=" + modal?.feedId
    );

    if (success) {
      setToastModal({ text: "게시글을 삭제했습니다.", check: true });
      handleClose();
      navigate(-1);
    } else {
      window.alert(alert);
    }
  };

  const handleReport = async (value: string[]) => {
    const formData = new FormData();
    formData.append("target_type", modal?.type ? modal?.type : "coffeed");
    //@ts-ignore
    formData.append("target_id", modal?.feedId);
    formData.append("reason", value.join(","));

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/report", formData);

    if (success) {
      setAlertModal({ title: "신고 완료", text: "신고가 완료되었습니다." });
      handleClose();
    } else {
      window.alert(alert);
    }
  };

  const handleBlock = async () => {
    const formData = new FormData();
    //@ts-ignore
    formData.append("target_user_id", modal?.userId);

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/user/block", formData);

    if (success) {
      if (data2?.type === 1)
        setToastModal({ text: "차단되었습니다.", check: true });
      navigate(-1);
    } else {
      window.alert(alert);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>더보기</PopupTitle>
        <PopupText>
          {modal?.myFeed ? (
            <>
              <List
                onClick={() => {
                  handleClose();
                  navigate(
                    modal?.type === "coffee_talk"
                      ? "/talk/edit/" + modal?.feedId
                      : modal?.type === "coffeed"
                      ? "/feed/edit/" + modal?.feedId
                      : `/order/${modal?.type}/review/${modal?.userId}/${modal?.feedId}`
                  );
                }}
              >
                수정
              </List>
              <List
                onClick={() =>
                  setTwoButtonModal({
                    title:
                      modal?.type === "coffee_talk"
                        ? "커피톡 삭제"
                        : modal?.type === "coffeed"
                        ? "커피드 삭제"
                        : "리뷰 삭제",
                    text:
                      modal?.type === "coffee_talk"
                        ? "작성된 커피톡을 삭제하시겠습니까?"
                        : modal?.type === "coffeed"
                        ? "작성된 커피드를 삭제하시겠습니까?"
                        : "작성된 리뷰를 삭제하시겠습니까?",
                    confirmText: "삭제",
                    onConfirm:
                      modal?.type === "market" || modal?.type === "cafe"
                        ? handleReviewDelete
                        : handleDelete,
                  })
                }
              >
                삭제
              </List>
            </>
          ) : modal?.mySubscribe ? (
            <>
              <List
                onClick={() => {
                  handleClose();
                  navigate(`/mypage/subscribe/editAddress/all`);
                }}
              >
                배송지 일괄 변경
                <Arrow />
              </List>
              <List
                onClick={() => {
                  handleClose();
                  card
                    ? navigate("/mypage/subscribe/card")
                    : navigate("/mypage/subscribe/verification");
                }}
              >
                결제 카드 변경
                {card && <span>{card}</span>}
                <Arrow />
              </List>
            </>
          ) : (
            <>
              <List
                onClick={() =>
                  setReportModal({ onClick: (value) => handleReport(value) })
                }
              >
                작성자 신고
              </List>
              <List
                onClick={() =>
                  setTwoButtonModal({
                    title: "작성자 차단",
                    text: "선택 회원을 차단하시겠습니까?",
                    confirmText: "차단",
                    onConfirm: handleBlock,
                  })
                }
              >
                작성자 차단
              </List>
            </>
          )}
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default FeedMenuModal;

const List = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray1};
  letter-spacing: -0.028px;

  &.select {
    font-weight: 700;
  }

  & > svg {
    width: 14px;
    height: 10px;
  }
  & span {
    flex: 1;
    font-weight: 600;
    font-size: 14px;
    color: #ff6682;
    text-align: right;
    margin-right: 10px;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding: 3px 20px 20px;
`;

const PopupTitle = styled.div`
  padding: 26px 20px 16px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
