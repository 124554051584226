import React from "react";
import styled from "styled-components";

const JoinTitle = styled.h1`
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};
`;

const JoinText = styled.p`
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  color: ${(props) => props.theme.color.gray3};
`;

const PopupHeader = styled.div`
  padding: 26px 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
`;

const PopupTitle = styled.h1`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
`;

const PopupText = styled.p`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.028px;
  color: ${(props) => props.theme.color.gray2};
  white-space: pre-line;
  & > b {
    font-weight: 500;
  }
  & span {
    font-weight: 700;
    color: #ff6682;
  }
`;

const MainTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};

  & > span.rTextColor {
    color: ${(props) => props.theme.color.red};
  }
`;

const BackWrapperCenter = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: ${(props) => props.theme.color.gray1};
  text-align: center;
  & span.my {
    font-size: 12px;
    font-weight: 700;
    line-height: 19.2px;
    color: #adadbc;
  }
`;

const CloseWrapperCenter = styled.div`
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  color: white;
  & span.my {
    font-size: 12px;
    font-weight: 700;
    line-height: 19.2px;
    color: #adadbc;
  }
`;

export {
  BackWrapperCenter,
  CloseWrapperCenter,
  MainTitle,
  JoinTitle,
  JoinText,
  PopupHeader,
  PopupTitle,
  PopupText,
};
