import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { cafeInfoModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import CafeList from "../list/CafeList";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";

const CafeInfoModal = () => {
  const [modal, setModal] = useRecoilState(cafeInfoModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      }, 200);
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.bottom = "-100%";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  if (modal === null) return null;

  console.log(modal?.item);

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>
          카페 정보
          <Close onClick={handleClose} />
        </PopupTitle>
        <PopupText>
          <CafeList
            type="map"
            distance={modal?.item?.distance}
            end_time={modal?.item?.end_time}
            id={modal?.item?.id}
            is_my_like={modal?.item?.is_my_like}
            is_open={modal?.item?.is_open}
            is_close={modal?.item?.is_open === 1 ? "N" : "Y"}
            image_url={modal?.item?.main_image_url}
            name={modal?.item?.name}
            review_count={modal?.item?.review_count}
            review_score={modal?.item?.review_score}
            start_time={modal?.item?.start_time}
            tag={modal?.item?.tag}
            order_type={modal?.item?.order_type}
            holidays={modal?.item?.holidays}
            address={modal?.item?.address}
            address_detail={modal?.item?.address_detail}
          />
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CafeInfoModal;

const PopupText = styled.div`
  width: 100%;
`;

const PopupTitle = styled.div`
  padding: 26px 20px 16px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;

  & > button {
    margin: 0 20px 20px;
    width: calc(100% - 40px);
  }
`;
