import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { setBuyModalState, toastModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { Dot } from "../../utils/CommonFunction";
import Amount from "../elements/Amount";
import Button from "../elements/Button";
import { api } from "../../utils/api";
import { ProductOptionType } from "../../types/api";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Arrow } from "../../assets/icons/select-button-arrow-icon.svg";
import { ReactComponent as Close } from "../../assets/icons/close-gray-icon.svg";
import { ReactComponent as AllCheckAct } from "../../assets/icons/all-check-active-icon.svg";
import { ReactComponent as AllCheck } from "../../assets/icons/all-check-icon.svg";
import { ReactComponent as AllCheckDis } from "../../assets/icons/all-check-disabled-icon.svg";

const SetBuyModal = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useRecoilState(setBuyModalState);
  const setToastModal = useSetRecoilState(toastModalState);

  const [total, setTotal] = useState<number>(0);
  const [amount, setAmount] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [option, setOption] = useState<ProductOptionType[]>([]);
  const [select, setSelect] = useState<
    {
      option: ProductOptionType[];
      name: string;
      price: number;
      amount: number;
      qty: number;
    }[]
  >([]);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (modal === null) {
      setSelect([]);
    }
  }, [modal]);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  useEffect(() => {
    if (modal !== null) {
      if (modal?.product?.options?.length > 0) {
        let num = 0;

        for (const slt of select) {
          num += slt?.price * slt?.amount;
        }

        setTotal(num);
      } else {
        setTotal(modal?.product?.price * amount);
      }
    }
  }, [amount, select, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
      setOpen(false);
    }, 200);
  };

  const handleSelectOption = () => {
    let arr = [...select];
    let arr2 = [...option];
    let name = "",
      price = 0,
      qty = null;

    for (const opt of arr2) {
      name += (name === "" ? "" : ",") + opt?.option_name;
      price += opt?.option_price;
      if (qty === null || qty > opt?.qty) qty = opt?.qty;
    }

    arr.unshift({
      option: arr2,
      amount: 1,
      name: name,
      price: price, //@ts-ignore
      qty: qty,
    });
    setSelect(arr);
    setOption([]);
    setOpen(false);
  };

  const handleRegister = async (type: "cart" | "buy") => {
    if (modal !== null) {
      if (modal?.product?.options?.length > 0 && select?.length === 0) {
        setToastModal({
          text: "옵션을 먼저 선택해주세요.",
          check: false,
        });
      } else {
        if (type === "cart") {
          const formData = new FormData();
          formData.append("market_product_id", String(modal?.product?.id));
          if (modal?.product?.options?.length > 0) {
            for (let i = 0; i < select.length; i++) {
              for (const opt of select[i]?.option) {
                formData.append(
                  `market_product_options[${i}][option_id][]`,
                  String(opt?.id)
                );
              }
              formData.append(
                `market_product_options[${i}][qty]`,
                String(select[i]?.amount)
              );
            }
          } else formData.append("qty", String(amount));

          const {
            data: { success, alert, data: data2 },
          } = await api.post("/market/cart/set/register", formData);

          if (success) {
            setToastModal({
              text: "선택한 상품을 장바구니에 담았어요!",
              check: true,
            });
          } else {
            window.alert(alert);
          }
        } else {
          window.localStorage.setItem(
            "marketPayment",
            JSON.stringify({
              is_set: "1",
              product: modal?.product,
              select: select,
              amount: amount,
              set_delivery_price: modal?.set_delivery_price,
              set_free_delivery_minimum: modal?.set_free_delivery_minimum,
            })
          );
          navigate("/market/payment/set?direct=true");
        }
      }
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <Handle>
          <div />
        </Handle>
        {modal?.product?.options?.length > 0 ? (
          <OptionBox className={open ? "open" : ""}>
            <button type="button" onClick={() => setOpen(!open)}>
              {open
                ? `${option?.length}개 옵션 선택 (${option?.length}/${modal?.product?.max_option})`
                : `옵션 선택 (최대 ${modal?.product?.max_option}개)`}
              <Arrow />
            </button>
            {select?.length > 0 && !open && (
              <OptionSelectArea>
                {select?.map((slt, i) => (
                  <OptionSelect key={"select_order_option_" + i}>
                    <p>
                      {slt?.name}
                      <Close
                        onClick={() => {
                          let arr = [...select];
                          arr.splice(i, 1);
                          setSelect(arr);
                        }}
                      />
                    </p>
                    <div>
                      <p>{Dot(slt?.price)}원</p>
                      <Amount
                        amount={slt?.amount}
                        setAmount={(amt: number) => {
                          let arr = [...select];
                          arr[i].amount = amt;
                          setSelect(arr);
                        }}
                        max={slt?.qty}
                      />
                    </div>
                  </OptionSelect>
                ))}
              </OptionSelectArea>
            )}
          </OptionBox>
        ) : (
          <NoneOption>
            <h1>{modal?.product?.product_name}</h1>
            <div>
              <p>{Dot(modal?.product?.price)}원</p>
              <Amount
                amount={amount}
                setAmount={setAmount}
                max={modal?.product?.qty}
              />
            </div>
          </NoneOption>
        )}
        {open ? (
          <OptionList>
            <div>
              {modal?.product?.options?.map((item, i) => {
                let select = false;
                let index = -1;

                for (let i = 0; i < option.length; i++) {
                  if (item.id === option[i].id) {
                    select = true;
                    index = i;
                  }
                }

                return (
                  <Options
                    key={"subscribe_options_list_" + i}
                    className={
                      (select ? "select" : "") +
                      (item?.qty <= 0 ? " soldout" : "")
                    }
                    onClick={() => {
                      if (item?.qty <= 0) {
                        setToastModal({
                          text: "품절된 옵션은 선택할 수 없어요.",
                          check: false,
                        });
                      } else {
                        let arr = [...option];

                        if (!select) {
                          if (option?.length < modal?.product?.max_option) {
                            arr.push(item);
                          } else {
                            setToastModal({
                              text: `옵션은 최대 ${modal?.product?.max_option}개까지 선택할 수 있어요.`,
                              check: false,
                            });
                          }
                        } else {
                          if (index >= 0) arr.splice(index, 1);
                        }

                        setOption(arr);
                      }
                    }}
                  >
                    <div>
                      {item?.qty <= 0 ? (
                        <AllCheckDis />
                      ) : select ? (
                        <AllCheckAct />
                      ) : (
                        <AllCheck />
                      )}
                      <div>
                        <p>
                          {item?.option_name?.slice(0, 20)}
                          {item?.qty <= 0 && <span>품절</span>}
                          {item?.qty <= 4 && item?.qty > 0 && (
                            <span className="near">품절 임박</span>
                          )}
                        </p>
                        <h1>{Dot(item?.option_price)}원</h1>
                      </div>
                    </div>
                  </Options>
                );
              })}
            </div>
          </OptionList>
        ) : (
          <TotalArea>
            <span />
            <TotalPrice>
              <div>총 상품 금액</div>
              <div>{Dot(total)}원</div>
            </TotalPrice>
            <TotalPrice>
              <div>
                배송비
                <span>
                  ({Dot(Number(modal?.set_free_delivery_minimum))}원 이상 구매시
                  무료배송)
                </span>
              </div>
              <div>
                {Dot(
                  total > Number(modal?.set_free_delivery_minimum)
                    ? 0
                    : Number(modal?.set_delivery_price)
                )}
                원
              </div>
            </TotalPrice>
            <TotalPrice>
              <div>총 결제금액</div>
              <div>
                <b>
                  {Dot(
                    total +
                      (total > Number(modal?.set_free_delivery_minimum)
                        ? 0
                        : Number(modal?.set_delivery_price))
                  )}
                  원
                </b>
              </div>
            </TotalPrice>
          </TotalArea>
        )}
        <ButtonArea className={open ? "open" : ""}>
          {open ? (
            <Button disabled={option?.length <= 0} onClick={handleSelectOption}>
              옵션 선택 완료
            </Button>
          ) : (
            <>
              <Button className="line" onClick={() => handleRegister("cart")}>
                장바구니
              </Button>
              <Button onClick={() => handleRegister("buy")}>바로 구매</Button>
            </>
          )}
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SetBuyModal;

const OptionSelect = styled.div`
  padding: 20px 0 14px;

  & > p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};
    margin-bottom: 7px;
  }

  & > div {
    display: flex;
    align-items: flex-end;

    & > p {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.5;
      color: ${(props) => props.theme.color.gray1};
      width: 100%;
      padding-bottom: 23px;
    }
  }

  &:nth-of-type(n + 2) {
    border-top: solid 1px ${(props) => props.theme.color.line};
  }

  &:last-of-type {
    padding-bottom: 6px;
  }
`;

const OptionSelectArea = styled.div`
  margin-top: 12px;
  max-height: 300px;
  overflow: auto;
`;

const Options = styled.div`
  width: 100%;
  padding: 0 11px;
  cursor: pointer;

  &:nth-of-type(n + 2) > div {
    border-top: solid 1px ${(props) => props.theme.color.line};
  }

  &.select {
    background: ${(props) => props.theme.color.gray7};
  }

  & > div {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > svg {
      width: 19px;
      min-width: 19px;
      height: 19px;
      margin-right: 12px;
    }

    & > div {
      width: 100%;

      & > p {
        max-width: 207px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: ${(props) => props.theme.color.gray1};
        display: flex;
        align-items: flex-start;

        & > span {
          white-space: nowrap;
          padding: 4px 5px;
          border-radius: 2px;
          background: ${(props) => props.theme.color.gray7};
          font-size: 10px;
          font-weight: 600;
          letter-spacing: -0.2px;
          color: ${(props) => props.theme.color.gray4};
          margin-left: 5px;

          &.near {
            background: rgba(255, 102, 130, 0.1);
            color: ${(props) => props.theme.color.red};
          }
        }
      }

      & > h1 {
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;
        color: ${(props) => props.theme.color.gray1};
      }
    }
  }

  &.soldout > div > div {
    & > p {
      color: ${(props) => props.theme.color.gray4};
    }

    & > h1 {
      color: ${(props) => props.theme.color.gray4};
    }
  }
`;

const OptionList = styled.div`
  padding: 0 20px 20px;

  & > div {
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.color.gray1};
    padding: 4px 0;
  }
`;

const OptionBox = styled.div`
  padding: 18px 20px 8px;

  &.open {
    padding: 18px 20px 4px;

    & > button {
      border-color: ${(props) => props.theme.color.gray1};
      color: ${(props) => props.theme.color.gray1};

      & > svg {
        transform: rotate(180deg);
      }
    }
  }

  & > button {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.color.gray6};
    background: ${(props) => props.theme.color.white};
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray4};

    & > svg {
      transition: all 0.3s;
    }
  }
`;

const ButtonArea = styled.div`
  padding: 9px 18px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px ${(props) => props.theme.color.line};

  & > button {
    width: calc(50% - 6px);
  }

  &.open > button {
    width: 100%;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  &:first-of-type {
    margin-top: 17px;
  }

  & > div {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};

    & > span {
      margin-left: 5px;
      color: ${(props) => props.theme.color.gray4};
    }

    & > b {
      font-size: 17px;
      font-weight: 700;
      letter-spacing: -0.2px;
      color: ${(props) => props.theme.color.red};
    }
  }
`;

const TotalArea = styled.div`
  padding: 12px 20px 17px;

  & > span {
    display: block;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.color.line};
  }
`;

const NoneOption = styled.div`
  padding: 20px 20px 16px;

  & > h1 {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};
  }

  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > p {
      padding: 2px 0 0;
      margin-right: 20px;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.5;
      color: ${(props) => props.theme.color.gray1};
    }
  }
`;

const Handle = styled.div`
  padding: 10px 0 12px;
  display: flex;
  justify-content: center;

  & > div {
    width: 36px;
    height: 3px;
    border-radius: 50px;
    background: ${(props) => props.theme.color.gray6};
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
