import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { subscribeModalState, toastModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { Dot } from "../../utils/CommonFunction";
import Amount from "../elements/Amount";
import Button from "../elements/Button";
import { api } from "../../utils/api";
import { ProductOptionType } from "../../types/api";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Arrow } from "../../assets/icons/select-button-arrow-icon.svg";
import { ReactComponent as Close } from "../../assets/icons/close-gray-icon.svg";

const SubscribeModal = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useRecoilState(subscribeModalState);
  const setToastModal = useSetRecoilState(toastModalState);

  const [total, setTotal] = useState<number>(0);
  const [amount, setAmount] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [option, setOption] = useState<
    { option: ProductOptionType; amount: number }[]
  >([]);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (modal === null) {
      setOption([]);
    }
  }, [modal]);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  useEffect(() => {
    if (modal !== null) {
      if (modal?.product?.options?.length > 0) {
        let num = 0;

        for (const opt of option) {
          num += opt?.option?.option_subscribe_price * opt?.amount;
        }

        setTotal(num);
      } else {
        setTotal(modal?.product?.subscribe_price * amount);
      }
    }
  }, [amount, option, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";
    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleRegister = async (type: "cart" | "buy") => {
    if (modal !== null) {
      if (modal?.product?.options?.length > 0 && option?.length === 0) {
        setToastModal({
          text: "옵션을 먼저 선택해주세요.",
          check: false,
        });
      } else {
        if (type === "cart") {
          const formData = new FormData();
          formData.append("is_subscribe", "1");
          formData.append("market_product_id", String(modal?.product?.id));
          if (modal?.product?.options?.length > 0) {
            for (const op of option) {
              formData.append(
                `market_product_options[${op?.option?.id}]`,
                String(op?.amount)
              );
            }
          } else formData.append("qty", String(amount));

          const {
            data: { success, alert, data: data2 },
          } = await api.post("/market/cart/register", formData);

          if (success) {
            setToastModal({
              text: "선택한 상품을 마켓 장바구니에 담았어요!",
              check: true,
            });
          } else {
            window.alert(alert);
          }
        } else {
          handleClose();
          window.localStorage.setItem(
            "marketPayment",
            JSON.stringify({
              product: modal?.product,
              options: option,
              amount: amount,
              set_delivery_price: modal?.set_delivery_price,
              set_free_delivery_minimum: modal?.set_free_delivery_minimum,
            })
          );
          navigate("/market/payment/subscribe?direct=true");
        }
      }
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <Handle>
          <div />
        </Handle>
        {modal?.product?.options?.length > 0 ? (
          <OptionBox className={open ? "open" : ""}>
            <button type="button" onClick={() => setOpen(!open)}>
              옵션 선택
              <Arrow />
            </button>
            {option?.length > 0 && !open && (
              <OptionSelectArea>
                {option?.map((opt, i) => (
                  <OptionSelect key={"select_order_option_" + i}>
                    <p>
                      {opt?.option?.option_name}
                      <Close
                        onClick={() => {
                          let arr = [...option];
                          arr.splice(i, 1);
                          setOption(arr);
                        }}
                      />
                    </p>
                    <h1>{Dot(opt?.option?.option_price)}원</h1>
                    <div>
                      <p>
                        <span>{opt?.option?.subscribe_sale_percent}%</span>월{" "}
                        {Dot(opt?.option?.option_subscribe_price)}원
                      </p>
                      <Amount
                        amount={opt?.amount}
                        setAmount={(amt: number) => {
                          let arr = [...option];
                          arr[i].amount = amt;
                          setOption(arr);
                        }}
                        max={opt?.option?.qty}
                      />
                    </div>
                  </OptionSelect>
                ))}
              </OptionSelectArea>
            )}
          </OptionBox>
        ) : (
          <NoneOption>
            <h1>{modal?.product?.product_name}</h1>
            <div>
              <NoneOptionText>
                <h1>{Dot(modal?.product?.price)}원</h1>
                <p>
                  <span>{modal?.product?.subscribe_sale_percent}%</span>월{" "}
                  {Dot(modal?.product?.subscribe_price)}원
                </p>
              </NoneOptionText>
              <Amount
                amount={amount}
                setAmount={setAmount}
                max={modal?.product?.qty}
              />
            </div>
          </NoneOption>
        )}
        {open ? (
          <OptionList>
            <div>
              {modal?.product?.options?.map((item, i) => {
                let select = false;

                for (const op of option) {
                  if (item.id === op.option.id) select = true;
                }

                return (
                  <Options
                    key={"subscribe_options_list_" + i}
                    className={
                      (select ? "select" : "") +
                      (item?.qty > 0 ? "" : " soldout")
                    }
                    onClick={() => {
                      if (item?.qty <= 0) {
                        setToastModal({
                          text: "품절된 옵션은 선택할 수 없어요.",
                          check: false,
                        });
                      } else {
                        let arr = [...option];

                        if (!select) {
                          arr.unshift({ option: item, amount: 1 });
                        } else {
                          setToastModal({
                            text: "이미 선택한 옵션이에요.",
                            check: false,
                          });
                        }

                        setOpen(false);
                        setOption(arr);
                      }
                    }}
                  >
                    <div>
                      <p>
                        {item?.option_name?.slice(0, 20)}
                        {item?.qty <= 0 && <span>품절</span>}
                      </p>
                      <div>
                        구매가 {Dot(item?.option_price)}원
                        <div>
                          <span>{item?.subscribe_sale_percent}%</span>월{" "}
                          {Dot(item?.option_subscribe_price)}원
                        </div>
                      </div>
                    </div>
                  </Options>
                );
              })}
            </div>
          </OptionList>
        ) : (
          <>
            <TotalArea>
              <span />
              <TotalPrice>
                <div>총 상품 금액</div>
                <div>{Dot(total)}원</div>
              </TotalPrice>
              <TotalPrice>
                <div>
                  배송비
                  <span>
                    ({Dot(Number(modal?.set_free_delivery_minimum))}원 이상
                    구매시 무료배송)
                  </span>
                </div>
                <div>
                  {Dot(
                    total > Number(modal?.set_free_delivery_minimum)
                      ? 0
                      : Number(modal?.set_delivery_price)
                  )}
                  원
                </div>
              </TotalPrice>
              <TotalPrice>
                <div>월 정기 결제금액</div>
                <div>
                  <b>
                    {Dot(
                      total +
                        (total > Number(modal?.set_free_delivery_minimum)
                          ? 0
                          : Number(modal?.set_delivery_price))
                    )}
                    원
                  </b>
                </div>
              </TotalPrice>
            </TotalArea>
            <ButtonArea>
              <Button className="line" onClick={() => handleRegister("cart")}>
                장바구니
              </Button>
              <Button onClick={() => handleRegister("buy")}>구독 시작</Button>
            </ButtonArea>
          </>
        )}
      </Wrapper>
    </ModalWrapper>
  );
};

export default SubscribeModal;

const OptionSelect = styled.div`
  padding: 20px 0 14px;

  & > p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};
  }

  & > h1 {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: line-through;
    color: ${(props) => props.theme.color.gray4};
    margin-top: 7px;
  }

  & > div {
    display: flex;
    align-items: flex-start;

    & > p {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.5;
      color: ${(props) => props.theme.color.gray1};
      width: 100%;

      & > span {
        margin-right: 3px;
        font-weight: 700;
        color: ${(props) => props.theme.color.red};
      }
    }
  }

  &:nth-of-type(n + 2) {
    border-top: solid 1px ${(props) => props.theme.color.line};
  }

  &:last-of-type {
    padding-bottom: 6px;
  }
`;

const OptionSelectArea = styled.div`
  margin-top: 12px;
  max-height: 300px;
  overflow: auto;
`;

const Options = styled.div`
  width: 100%;
  padding: 0 11px;
  cursor: pointer;

  &:nth-of-type(n + 2) > div {
    border-top: solid 1px ${(props) => props.theme.color.line};
  }

  &.select {
    background: ${(props) => props.theme.color.gray7};
  }

  &.soldout {
    & > div {
      & > p {
        color: ${(props) => props.theme.color.gray4};
      }

      & > div > div {
        color: ${(props) => props.theme.color.gray4};

        & > span {
          color: ${(props) => props.theme.color.redSub};
        }
      }
    }
  }

  & > div {
    padding: 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: ${(props) => props.theme.color.gray1};
      display: flex;
      align-items: center;

      & > span {
        white-space: nowrap;
        padding: 4px 5px;
        border-radius: 2px;
        background: ${(props) => props.theme.color.gray7};
        font-size: 10px;
        font-weight: 600;
        letter-spacing: -0.2px;
        color: ${(props) => props.theme.color.gray4};
        margin-left: 5px;
      }
    }

    & > div {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.5;
      color: ${(props) => props.theme.color.gray4};
      text-align: right;

      & > div {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.5;
        color: ${(props) => props.theme.color.gray1};

        & > span {
          margin-right: 5px;
          font-weight: 700;
          color: ${(props) => props.theme.color.red};
        }
      }
    }
  }
`;

const OptionList = styled.div`
  padding: 0 20px 30px;

  & > div {
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.color.gray1};
    padding: 4px 0;
  }
`;

const OptionBox = styled.div`
  padding: 18px 20px 8px;

  &.open {
    padding: 18px 20px 4px;

    & > button {
      border-color: ${(props) => props.theme.color.gray1};

      & > svg {
        transform: rotate(180deg);
      }
    }
  }

  & > button {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.color.gray6};
    background: ${(props) => props.theme.color.white};
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray4};

    & > svg {
      transition: all 0.3s;
    }
  }
`;

const ButtonArea = styled.div`
  padding: 9px 18px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px ${(props) => props.theme.color.line};

  & > button {
    width: calc(50% - 6px);
  }
`;

const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  &:first-of-type {
    margin-top: 17px;
  }

  & > div {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};

    & > span {
      margin-left: 5px;
      color: ${(props) => props.theme.color.gray4};
    }

    & > b {
      font-size: 17px;
      font-weight: 700;
      letter-spacing: -0.2px;
      color: ${(props) => props.theme.color.red};
    }
  }
`;

const TotalArea = styled.div`
  padding: 12px 20px 17px;

  & > span {
    display: block;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.color.line};
  }
`;

const NoneOptionText = styled.div`
  width: 100%;
  margin-right: 20px;

  & > h1 {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: line-through;
    color: ${(props) => props.theme.color.gray4};
  }

  & > p {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    color: ${(props) => props.theme.color.gray1};

    & > span {
      color: ${(props) => props.theme.color.red};
      font-weight: 700;
      margin-right: 3px;
    }
  }
`;

const NoneOption = styled.div`
  padding: 20px;

  & > h1 {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray1};
  }

  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const Handle = styled.div`
  padding: 10px 0 12px;
  display: flex;
  justify-content: center;

  & > div {
    width: 36px;
    height: 3px;
    border-radius: 50px;
    background: ${(props) => props.theme.color.gray6};
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
